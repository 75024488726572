import { Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getUserPrivacyStart,
  updateUserPrivacyStart,
  loadProfileAttributesStart,
  loadUserEmailPreferenceCenterStart,
  updateProfileAttributesStart,
  loadUserSmsSubscriptionStatusStart,
  updateUserSmsSubscriptionStatusStart,
} from '../../redux/modules/profile'
import { isSmsSubscribed } from '../../util/profileUtils'
import { PanelPrivacyPreferences } from './PanelPrivacyPreferences'
import { PanelNotificationPreferences } from './PanelNotificationPreference'
import ModalUnsubscribeSms from './ModalUnsubscribeSms'
import ModalConfirmPhone from './ModalConfirmPhone'

class ProfileSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedData: false,
      smsToggleStatus: isSmsSubscribed(this.props.smsSubscriptionStatus),
      showConfirmPhoneModal: false,
      showUnsubscribeModal: false,
    }
  }

  componentDidMount() {
    const {
      profile,
      loadProfileAttributesStart,
      loadUserEmailPreferenceCenterStart,
      loadUserSmsSubscriptionStatusStart,
      smsSubscriptionStatus,
      isLoadingSmsSubscriptionStatus,
      privacy,
      userPrivacyLoading,
      updateUserPrivacyError,
      getUserPrivacyStart,
    } = this.props
    loadProfileAttributesStart()

    if (profile && !this.props.emailPreferenceUrl) {
      loadUserEmailPreferenceCenterStart(profile.userId)
    }

    if (!privacy && !userPrivacyLoading && profile && !updateUserPrivacyError) {
      getUserPrivacyStart()
    }

    if (profile) {
      const phoneNumber = profile.phone.replace(/\D/g, '')
      loadUserSmsSubscriptionStatusStart(profile.userId, phoneNumber)
    }

    if (!isLoadingSmsSubscriptionStatus) {
      this.setState({ smsToggleStatus: isSmsSubscribed(smsSubscriptionStatus) })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getUserPrivacyStart,
      isLoadingEmailPreferenceUrl,
      privacy,
      profile,
      updateUserPrivacyError,
      userPrivacyLoading,
      loadUserEmailPreferenceCenterStart,
      smsSubscriptionStatus,
      emailPreferenceUrl,
    } = this.props

    if (
      !privacy &&
      !userPrivacyLoading &&
      profile &&
      !updateUserPrivacyError &&
      prevProps.privacy !== privacy
    ) {
      getUserPrivacyStart()
    }

    if (!emailPreferenceUrl && profile && !isLoadingEmailPreferenceUrl) {
      loadUserEmailPreferenceCenterStart(profile.userId)
    }

    if (prevProps.smsSubscriptionStatus !== smsSubscriptionStatus) {
      this.setState({ smsToggleStatus: isSmsSubscribed(smsSubscriptionStatus) })
    }

    if (this.state.fetchedData === false && !userPrivacyLoading && !isLoadingEmailPreferenceUrl) {
      this.setState({ fetchedData: true })
    }
  }

  render() {
    const {
      isLoadingEmailPreferenceUrl,
      privacy,
      updateUserPrivacyError,
      updateUserPrivacyStart,
      userPrivacyLoading,
      emailPreferenceUrl,
      errorSettingSmsSubscriptionStatus,
      isLoadingSmsSubscriptionStatus,
      updateUserSmsSubscriptionStatusStart,
      isLoadingUpdatedSmsSubscriptionStatus,
      profile,
    } = this.props

    const findModalToDisplay = event => {
      const { smsToggleStatus } = this.state
      if (smsToggleStatus) {
        this.setState({ showUnsubscribeModal: true })
      } else {
        this.setState({ showConfirmPhoneModal: true })
      }
    }

    const setSmsToggleStatus = event => {
      const phoneNumber = profile.phone.replace(/\D/g, '')
      updateUserSmsSubscriptionStatusStart(profile.userId, phoneNumber)
      this.setState({ showUnsubscribeModal: false })
      this.setState({ showConfirmPhoneModal: false })
    }

    const closeModal = event => {
      this.setState({ showUnsubscribeModal: false })
      this.setState({ showConfirmPhoneModal: false })
    }

    return (
      <>
        <PanelNotificationPreferences
          isLoadingEmailPreferenceUrl={isLoadingEmailPreferenceUrl}
          emailPreferenceUrl={emailPreferenceUrl}
          errorSettingSmsSubscriptionStatus={errorSettingSmsSubscriptionStatus}
          isLoadingSmsSubscriptionStatus={isLoadingSmsSubscriptionStatus}
          findModalToDisplay={findModalToDisplay}
          smsToggleStatus={this.state.smsToggleStatus}
          userPrivacyLoading={userPrivacyLoading}
          isLoadingUpdatedSmsSubscriptionStatus={isLoadingUpdatedSmsSubscriptionStatus}
        />
        <ModalConfirmPhone
          showConfirmPhoneModal={this.state.showConfirmPhoneModal}
          setSmsToggleStatus={setSmsToggleStatus}
          closeModal={closeModal}
          phone={profile.phone}
        />
        <ModalUnsubscribeSms
          showUnsubscribeModal={this.state.showUnsubscribeModal}
          setSmsToggleStatus={setSmsToggleStatus}
          closeModal={closeModal}
        />
        <Card>
          {(userPrivacyLoading || !this.state.fetchedData || isEmpty(privacy)) &&
          !this.state.fetchedData ? (
            <SkeletonPanelPrivacyPreferences />
          ) : (
            <PanelPrivacyPreferences
              privacy={privacy}
              updateUserPrivacyError={updateUserPrivacyError}
              updateUserPrivacyStart={updateUserPrivacyStart}
              userPrivacyLoading={userPrivacyLoading}
            />
          )}
        </Card>
      </>
    )
  }
}

const mapStateToProps = state => {
  const {
    privacy,
    profile,
    updateUserPrivacyError,
    userPrivacyLoading,
    additionalAttributes,
    emailPreferenceUrl,
    isUpdatingCuisinePreference,
    isLoadingEmailPreferenceUrl,
    errorSettingCuisinePreferences,
    errorUpdatingCuisinePreferences,
    errorSettingSmsSubscriptionStatus,
    smsSubscriptionStatus,
    isLoadingSmsSubscriptionStatus,
    isLoadingUpdatedSmsSubscriptionStatus,
  } = state.profile

  return {
    isUpdatingCuisinePreference,
    isLoadingEmailPreferenceUrl,
    privacy,
    profile,
    updateUserPrivacyError,
    userPrivacyLoading,
    additionalAttributes,
    emailPreferenceUrl,
    errorSettingCuisinePreferences,
    errorUpdatingCuisinePreferences,
    errorSettingSmsSubscriptionStatus,
    smsSubscriptionStatus,
    isLoadingSmsSubscriptionStatus,
    isLoadingUpdatedSmsSubscriptionStatus,
  }
}

const mapDispatchToProps = {
  getUserPrivacyStart,
  updateUserPrivacyStart,
  loadProfileAttributesStart,
  loadUserEmailPreferenceCenterStart,
  updateProfileAttributesStart,
  loadUserSmsSubscriptionStatusStart,
  updateUserSmsSubscriptionStatusStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar)

const SkeletonPanelPrivacyPreferences = () => (
  <>
    <Skeleton height={40} width="100%" />
    <Skeleton height={56} width="100%" />
  </>
)
